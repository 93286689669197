body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bgDark: #1b1821;
  --bgStrong: #312b3c;
  --bgMedium: #241B34;
  --bgLight: #463c5b;
  --textPrimary: #fafafa;
  --textSecondary: #c9bbaf;
  --contrast: #c59e60;
  --contrast2: #2d7f9d;
}

.simplebar-scrollbar::before {
  background-color: var(--contrast) !important;
}

.ReactVirtualized__List {
  outline: none;
}

.ReactVirtualized__Grid {
  overflow: visible !important;
}

.cleaveStyle {
  appearance: none;
  border: 0px;
  background-color: var(--bgDark);
  color: var(--textPrimary);
  border-radius: 2px;
  padding: 0px 0.5em 0px 0.5em;
  box-sizing: border-box;
}

.cleaveStyle:focus {
  outline: none;
  border-bottom: 2px solid var(--contrast);
}

.Toastify__toast--success {
    background-color: #188500 !important;
    font-size: 14px;
}